import { RefObject } from 'react';
import { assertIsDefined, useMediaQuery } from '@flame-frontend-utils/commons';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { useLayoutEffectWithoutSsrWarning } from './useLayoutEffectWithoutSsrWarning';
import { mediaWidth } from '../styles/width';

const MASONRY_SPACER = 'masonry-spacer';
const MASONRY_CONTAINER = 'masonry-container';

const useMasonryGroupResize = (rootRef: RefObject<HTMLElement>, maxCardHeight: number) => {
  const isAboveMobile = useMediaQuery(mediaWidth.m, { unsafe: true });

  useLayoutEffectWithoutSsrWarning(() => {
    // Masonry doesn't really exist on mobile, so not recalculation is needed
    if (!isAboveMobile) {
      return () => {};
    }

    const root = rootRef.current;
    assertIsDefined(root);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

    const resizeObserver = new ResizeObserver((entries) => {
      const affectedContainers = new Set(
        entries
          .map((entry) => entry.target.parentElement)
          .filter((parent): parent is HTMLElement => parent instanceof HTMLElement),
      );

      recalculateMasonryHeights([...affectedContainers], maxCardHeight);
    });

    const processContainer = (container: HTMLElement, observe: boolean) => {
      [...container.children].forEach((child) => {
        if (observe) {
          resizeObserver.observe(child);
        } else {
          resizeObserver.unobserve(child);
        }
      });
    };

    const onChildrenAdded = (mutations?: MutationRecord[]) => {
      if (!mutations) {
        const masonryContainers = getInnerMasonryContainers(root);

        recalculateMasonryHeights(masonryContainers, maxCardHeight);

        resizeObserver.disconnect();

        masonryContainers.forEach((container) => processContainer(container, true));
      } else {
        const addedMasonryContainers = getMasonryContainers(mutations.flatMap((m) => [...m.addedNodes]));
        const removedMasonryContainers = getMasonryContainers(mutations.flatMap((m) => [...m.removedNodes]));

        recalculateMasonryHeights(addedMasonryContainers, maxCardHeight);

        addedMasonryContainers.forEach((container) => processContainer(container, true));
        removedMasonryContainers.forEach((container) => processContainer(container, false));
      }
    };

    const mutationObserver = new MutationObserver(onChildrenAdded);

    onChildrenAdded();
    mutationObserver.observe(root, { childList: true });

    return () => {
      getInnerMasonryContainers(root).forEach((container) => {
        clearRootHeight(container);
      });

      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [isAboveMobile, rootRef, maxCardHeight]);
};

function recalculateMasonryHeights(masonryContainers: HTMLElement[], maxCardHeight: number) {
  const containerData = masonryContainers.map((container) => {
    const visibleChildren = [...container.children].filter((child) => {
      const el = child as HTMLElement;
      return !el.classList.contains(MASONRY_SPACER) && getComputedStyle(el).display !== 'none';
    }) as HTMLElement[];

    const columns = getColumns(visibleChildren);
    const cardsNumber = Math.ceil(visibleChildren.length / columns);

    setInitialRootHeight(container, cardsNumber, maxCardHeight);

    return {
      container,
      columns,
      cardsNumber,
      lastChildren: visibleChildren.slice(-columns),
      rootBoundsTop: container.getBoundingClientRect().top,
    };
  });

  containerData.forEach(({ container, lastChildren }) => {
    const childrenBottomEdges = lastChildren.map((child) => child.getBoundingClientRect().bottom);
    const rootBoundsTop = container.getBoundingClientRect().top;

    setOptimumRootHeight(container, childrenBottomEdges, rootBoundsTop);
  });
}

function getMasonryContainers(elements: Node[]): HTMLElement[] {
  const result: HTMLElement[] = [];

  elements.forEach((removedNode) => {
    if (removedNode instanceof HTMLElement) {
      if (removedNode.classList.contains(MASONRY_CONTAINER)) {
        result.push(removedNode);
      } else {
        result.push(...getInnerMasonryContainers(removedNode));
      }
    }
  });

  return result;
}

function getInnerMasonryContainers(root: HTMLElement): HTMLElement[] {
  return [...root.querySelectorAll(`.${MASONRY_CONTAINER}`)] as HTMLElement[];
}

function getColumns(elements: HTMLElement[]) {
  return elements.reduce((max, current) => {
    const currentOrder = parseInt(getComputedStyle(current).order, 10);

    if (currentOrder > max) {
      return currentOrder;
    }

    return max;
  }, 0);
}

function clearRootHeight(root: HTMLElement) {
  // eslint-disable-next-line no-param-reassign
  root.style.height = '';
  // eslint-disable-next-line no-param-reassign
  root.style.marginBottom = '';
}

function setInitialRootHeight(root: HTMLElement, cardsNumber: number, maxCardHeight: number) {
  // eslint-disable-next-line no-param-reassign
  root.style.height = `${cardsNumber * maxCardHeight}px`;
  // eslint-disable-next-line no-param-reassign
  root.style.marginBottom = '';
}

function setOptimumRootHeight(root: HTMLElement, childrenBottomEdges: number[], rootBoundsTop: number) {
  const bottomOfLowestChild = Math.max(...childrenBottomEdges);

  /** This is known to break for some fractional widths, so we add 1px to fix these cases. */
  // eslint-disable-next-line no-param-reassign
  root.style.height = `${bottomOfLowestChild - rootBoundsTop + 1}px`;
  /** And we compensate this added pixel so everything looks normal */
  // eslint-disable-next-line no-param-reassign
  root.style.marginBottom = '-1px';
}

export { useMasonryGroupResize, MASONRY_SPACER, MASONRY_CONTAINER };
