import { ReactNode } from 'react';
import { CARD_PADDING_BOTTOM, CARD_PADDING_BOTTOM_DESKTOP, CARD_PADDING_TOP_DESKTOP } from '../styles';
import { tw } from '../../../styles/tw';

declare module 'react' {
  interface CSSProperties {
    '--card-padding-bottom'?: string;
    '--card-padding-bottom-desktop'?: string;
    '--card-padding-top-desktop'?: string;
  }
}

const CardRoot = ({ className, children }: { className?: string; children?: ReactNode }) => (
  <section
    style={{
      '--card-padding-bottom': `${CARD_PADDING_BOTTOM / 16}rem`,
      '--card-padding-bottom-desktop': `${CARD_PADDING_BOTTOM_DESKTOP / 16}rem`,
      '--card-padding-top-desktop': `${CARD_PADDING_TOP_DESKTOP / 16}rem`,
    }}
    className={tw(
      'flex flex-col justify-between px-0 pb-[var(--card-padding-bottom)] pt-3 md:pb-[var(--card-padding-bottom-desktop)] md:pt-[var(--card-padding-top-desktop)]',
      className,
    )}
  >
    {children}
  </section>
);

const Subtitle = ({ className, children }: { className?: string; children?: ReactNode }) => (
  <span className={tw('text-text-secondary', className)}>{children}</span>
);

export { CardRoot, Subtitle };
