import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
import { getDefined } from '@flame-frontend-utils/commons';
import { Stream } from '../../lib/graphql.document';
import { MediaPostsDocument } from './gql/MediaPosts.document';
import { getBlocks } from './getBlocks';
import { ignoreReject } from '../../lib/ignoreReject';

interface UseMediaBlocksParams {
  stream: Stream;
  exclude?: string[];
}

const useMediaBlocks = ({ stream, exclude }: UseMediaBlocksParams) => {
  const { current: controller } = useRef(SSR_MODE ? undefined : new window.AbortController());

  useEffect(() => () => controller?.abort(), [controller, stream]);

  const blockSize = stream === Stream.Main ? PUBLIC_CONFIG.ARTICLES_PER_BLOCK : PUBLIC_CONFIG.BLOGS_PER_BLOCK;

  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(MediaPostsDocument, {
    variables: {
      filter: { stream, exclude },
      pagination: { first: blockSize },
    },
    fetchPolicy: 'cache-first',
    context: { fetchOptions: { signal: controller?.signal } },
  });

  const data = currentData || previousData;

  const hasNextPage = data?.mediaPosts.pageInfo.hasNextPage;

  const onFetchMore = useCallback(() => {
    if (hasNextPage) {
      void ignoreReject(
        fetchMore({
          variables: { pagination: { first: blockSize, after: data.mediaPosts.pageInfo.endCursor } },
        }),
      );
    }
  }, [data?.mediaPosts.pageInfo.endCursor, hasNextPage, fetchMore, blockSize]);

  const blocks = data
    ? getBlocks({
        posts: data.mediaPosts.items,
        blockSize,
        hasNextPage: getDefined(hasNextPage),
      })
    : null;

  return {
    loading,
    onFetchMore,
    isDataReceived: Boolean(data),
    mediaBlocks: blocks,
    hasNextPage: data?.mediaPosts.pageInfo.hasNextPage,
  };
};

export { useMediaBlocks };
